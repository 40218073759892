<style lang="scss">
  @import './ChatBotToggler.scss';
</style>

<template>
    <div class="chat-bot-toggler" v-on:click="itemClick">
        <label>CHATBOT</label>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
            <g>
                <path d="M546.8,389.4c-6.9-23.5-24.8-23.8-30.1-23.3c-1.5,0.1-3,0.4-4.5,0.8c6.6-27.3,11.2-57.6,12.2-85
                    c1.7-44.8-5.9-89.5-20.4-119.7c-0.1-0.1-0.1-0.2-0.2-0.4c8.9-17.8,14.7-39,16.4-60.1c1.1-14.1,0.6-32.4-4.6-37.9
                    c-2.2-2.3-5.4-2.9-8.1-1.6c-45.7,21.9-75,13.9-95.4,4.5c-3.8-2.1-7.7-4.1-11.6-6c-8.9-5.1-17.5-10.8-26.5-16.9
                    c-18.4-12.4-37.5-25.2-60.6-32.3C281.8,2,251.4-0.1,222.8,5.3c-36,6.9-67.6,25.9-93.9,56.3c-23.7,27.5-34.1,60.3-28.7,90.5
                    c-1.8,3.3-3.6,6.7-5.2,10c-14.5,30.2-22.1,74.9-20.4,119.7c1.1,27.3,5.6,57.6,12.2,84.8c-1.1-0.2-2.3-0.4-3.5-0.5
                    c-5.3-0.5-23.2-0.2-30.1,23.3c-5.3,18.1-1.3,40.4,10.3,56.9c9.6,13.7,23.1,21.8,38.1,22.7c4.5,0.3,8.3,0.4,11.5,0.4
                    c7,0,11.3-0.6,13.9-1.7c14,27.7,30.8,52.3,49.5,72.1c35.7,37.8,77.1,57.8,119.9,57.8c1.1,0,2.1,0,3.2,0
                    c43.9,1,86.4-18.9,122.9-57.5c18.8-20,35.8-44.8,49.9-72.7c2.6,1.3,7,2.1,14.7,2.1c3.2,0,7-0.1,11.5-0.4c15-0.9,28.5-9,38.1-22.7
                    C548.1,429.8,552.2,407.4,546.8,389.4z M86.4,281.4c-1.7-42.9,5.5-85.6,19.2-114.1c1.8-3.7,3.8-7.5,5.9-11.2
                    c0.7-1.2,0.9-2.7,0.6-4.1c-5.6-27.2,3.8-57.3,25.7-82.6C174.3,27.1,216.1,14,254,14c20.3,0,39.4,3.8,56,8.8
                    c21.5,6.6,39.8,18.9,57.5,30.8c8.9,6,18.1,12.1,27.4,17.4c0.1,0.1,0.2,0.1,0.3,0.2c3.8,1.8,7.6,3.8,11.4,5.9
                    c0.1,0.1,0.2,0.1,0.4,0.2c32.5,15.1,63.8,14.4,100.9-2.2c1,5,1.7,14.8,0.5,27.6c-1.3,14.1-5.2,35.3-16.3,56.3
                    c-0.9,1.7-0.9,3.7-0.1,5.4c0.5,0.9,1,1.9,1.4,2.9c0,0,0,0,0,0c13.7,28.5,20.9,71.2,19.2,114.1c-1.5,39.5-9.7,79.5-19.3,109
                    c-1-38.7-10.3-66.6-28.1-84.7c-30.9-35.2-27-59.5-25.1-71.2c0.1-0.5,0.2-1,0.2-1.5c0.9-2.8,9.2-11.6,14.7-17.4
                    c1.2-1.3,2.4-2.6,3.7-3.9c2-2.1,2.1-5.4,0.3-7.7c-1.8-2.3-5.1-2.9-7.6-1.4c-2.2,1.3-4.5,2.6-6.8,3.7
                    c-47.6,23.7-121.7,33.6-188.8,25c-56.1-7.1-87.8-15.8-109.3-30c-2.5-1.6-5.8-1.2-7.7,1.1c-1.9,2.3-1.9,5.6,0.2,7.8
                    c1.7,1.8,3.3,3.6,5,5.3c5.5,5.8,13.8,14.6,14.7,17.4c0.1,0.5,0.2,1,0.2,1.5c1.9,11.7,5.7,36-25.1,71.2
                    c-17.8,18.1-27.1,46-28.1,84.7C96.1,360.9,87.9,320.9,86.4,281.4z M102,461c-12.5-0.8-23.8-7.6-32-19.3
                    c-10.2-14.6-13.8-34.2-9.1-50c4.5-15.4,14.4-17.7,19.9-17.7c0.6,0,1.2,0,1.7,0.1c2.6,0.2,4.8,0.9,6.6,1.8
                    c2.1,7.8,4.3,15.3,6.7,22.3c-1.4-1.5-2.9-2.8-4.3-3.8c-2.5-1.7-4.9-2.7-7.2-2.6c-2.5,0.1-4.7,1.3-6.5,4c-2.2,3.3-3.5,8.9-3.5,17.4
                    c0,1.3,0.9,2.4,1.9,2.4c1.1,0,1.9-1.1,1.9-2.4c0-7.3,1-11.9,2.6-14.4c1-1.5,2.2-2.2,3.6-2.2c1.6,0,3.4,0.7,5.2,2
                    c5,3.6,10.3,11.1,13.1,19.4c1.4,4,2.6,8.1,3.3,11.1c-3-2.6-6.2-3.6-9-3.2c-1.6,0.2-3,0.8-4.1,1.9c-1.2,1.1-2.1,2.6-2.6,4.5
                    c-0.8,3.3-0.2,7.7,2.7,13c0.6,1.1,1.8,1.4,2.7,0.7c0.9-0.7,1.1-2.2,0.5-3.3c-2.1-3.9-2.6-6.9-2.2-9c0.2-0.9,0.6-1.5,1.1-2
                    c0.6-0.5,1.4-0.9,2.3-1c2.5-0.3,5.6,1,8.2,4.3c0.7,0.9,1.4,1.4,2,1.5c1.5,0.4,2.4-0.4,2.8-2.3l0-0.1c0.1-0.7,0.1-1.7-0.1-2.8
                    c-0.1-0.4-0.1-0.8-0.2-1.3c1.6,4.2,3.3,8.4,5,12.5l6.5,18.5C119,461.3,113.4,461.7,102,461z M413.7,531.8
                    c-34.1,36.1-73.5,54.7-114,53.7l-0.1,0l-0.1,0c-40.5,1-80-17.7-114.2-53.9c-27.8-29.5-51.3-70.2-67.3-115.9
                    c-2.9-49.1,5.1-82.4,24.3-101.9c0.1-0.1,0.2-0.2,0.2-0.3c34.6-39.4,30-68.5,28-80.9c-0.1-0.5-0.2-1.1-0.2-1.5
                    c-0.3-1.9-1.2-4-2.8-6.3c23.6,8.8,52.6,13.9,86.9,18.3c16.4,2.1,33.3,3.1,50.1,3.1c45.8,0,91.3-7.5,127.1-21.3
                    c-1.5,2.3-2.4,4.3-2.7,6.2c-0.1,0.5-0.2,1-0.2,1.5c-2,12.4-6.6,41.5,28,80.9c0.1,0.1,0.2,0.2,0.2,0.3
                    c19.2,19.4,27.2,52.8,24.3,101.9C465.2,461.5,441.6,502.3,413.7,531.8z M530,441.7c-8.2,11.7-19.5,18.5-32,19.3
                    c-11.4,0.7-17,0.3-19.7-0.1l10.6-30.3c0,0.2-0.1,0.5-0.1,0.7c-0.1,1.1-0.2,2.1-0.1,2.8l0,0.1c0.3,1.9,1.2,2.7,2.8,2.3
                    c0.6-0.2,1.3-0.7,2-1.5c2.6-3.3,5.7-4.6,8.2-4.3c0.9,0.1,1.7,0.4,2.3,1c0.5,0.5,0.9,1.2,1.1,2c0.5,2,0,5-2.2,9
                    c-0.6,1.1-0.4,2.6,0.5,3.3c0.9,0.7,2.1,0.4,2.7-0.7c2.8-5.3,3.4-9.7,2.7-13c-0.5-1.9-1.4-3.4-2.6-4.5c-1.2-1-2.6-1.7-4.1-1.9
                    c-2.7-0.3-6,0.7-9,3.2c0.7-3.1,1.9-7.2,3.3-11.1c2.9-8.2,8.1-15.8,13.1-19.4c1.9-1.3,3.7-2.1,5.2-2c1.4,0,2.6,0.7,3.6,2.2
                    c1.6,2.5,2.6,7.1,2.6,14.4c0,1.3,0.9,2.4,1.9,2.4c1.1,0,1.9-1.1,1.9-2.4c0-8.5-1.3-14.1-3.5-17.4c-1.7-2.7-4-3.9-6.5-4
                    c-2.3-0.1-4.8,0.9-7.2,2.6c-1.5,1.1-3.1,2.5-4.6,4.2c2.4-7,4.6-14.4,6.7-22.1c1.8-1.1,4.4-2,7.6-2.3c4.8-0.4,16.6,0.4,21.7,17.6
                    C543.8,407.5,540.2,427.1,530,441.7z"/>
                <path d="M225.1,327.8c-8.9-6.4-21.3-4.4-27.7,4.5c-6.4,8.9-4.4,21.3,4.5,27.7c8.9,6.4,21.3,4.4,27.8-4.5
                    C236,346.7,234,334.2,225.1,327.8z"/>
                <path d="M375.2,327.8c-8.9,6.4-10.9,18.8-4.5,27.7c6.4,8.9,18.9,10.9,27.8,4.5c8.9-6.4,10.9-18.8,4.5-27.7
                    C396.5,323.4,384.1,321.4,375.2,327.8z"/>
                <path d="M233.8,273.9c-0.7-0.3-1.5-0.6-2.2-0.9c-8.7-3.1-16-4-25.4-3.3c-9.2,1-16,3-23.7,7.4c-3.6,2.3-17.6,13.6-13.6,17.8
                    c1.6,0.4,15.6-6.7,19.7-8c6.4-1.9,11.5-2.6,18.3-2.5c6.3,0.3,11,1.2,16.8,3.4c0.2,0,1.2,0.5,1.4,0.6c11.7,5.7,10.6,9.3,18.6,3.9
                    C255.9,285.8,243.2,278.9,233.8,273.9z"/>
                <path d="M416.6,277.2c-7.7-4.4-14.5-6.5-23.7-7.4c-9.4-0.7-16.7,0.2-25.4,3.3c-0.8,0.3-1.5,0.6-2.2,0.9
                    c-9.5,4.9-22.1,11.9-9.8,18.4c8,5.5,6.9,1.8,18.6-3.9c0.2-0.1,1.3-0.6,1.4-0.6c5.7-2.2,10.5-3.1,16.8-3.4
                    c6.7-0.1,11.9,0.6,18.3,2.5c4.1,1.3,18,8.4,19.6,8C434.1,290.8,420.2,279.5,416.6,277.2z"/>
                <path d="M242.1,467.4c0,32,31.6,43.5,61,43.5h0.1h0.1c29.4,0,61-11.4,61-43.5c-20.4-0.3-40.8-0.4-61.1-0.4
                    C282.8,467,262.5,467.1,242.1,467.4z"/>
                <path d="M297.7,404.2c6.5,0,13.3-0.1,19.1-1.1c8.8-1.5,13.2-5.1,13.2-10.6c0-6.6-5.4-40.6-7.1-50.8l0-0.2c-0.2-1.2-0.8-2.2-1.8-2.8
                    c-0.9-0.7-2.1-1-3.2-0.8c-1.1,0.2-2.2,0.8-2.8,1.7c-0.7,0.9-1,2.1-0.8,3.2l0,0.1c2.6,16,7,44.4,7,49.5c0,0-0.6,1.1-6,2.1
                    c-5.1,0.9-11.8,0.9-17.6,0.9c-2.4,0-4.4,2-4.4,4.4C293.4,402.3,295.3,404.2,297.7,404.2z"/>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
  name: 'ChatBotToggler',
  methods: {
    itemClick(){
        this.$emit('click');
    }
  }
}
</script>